import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Container } from "./utils/index"

const Hero = styled.div`
  position: relative;
  color: white;
  padding: 12rem 0 7rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Container} {
    z-index: 100;
  }
`
const ImgWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .overlay {
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.52), transparent);
    width: 100%;
    height: 100%;
    z-index: 50;
  }
`
const Text = styled.div`
  @media (min-width: 960px) {
    width: 60%;
  }
  h1 {
    color: white;
    text-shadow: black 2px 2px 10px;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.8;
    font-family: rhode;
    margin-bottom: 2.5rem;
  }
`

export default ({ children, image }) => {
  return (
    <Hero>
      <ImgWrapper>
        <div className="overlay"></div>
        <Img
          critical
          className="hero-image"
          fluid={image.fluid}
          alt={image.alt}
          style={{ height: "100%" }}
        />
      </ImgWrapper>
      <Container>
        <Text>{children}</Text>
      </Container>
    </Hero>
  )
}
