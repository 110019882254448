import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Container, Section, ContactSection } from "../components/utils"
import SEO from "../components/seo"
import Hero from "../components/hero"
import styled from "styled-components"

const ServiceSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
  & > div,
  & > a {
    box-shadow: 1px black;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    flex: 1 1 100%;
    @media (min-width: 660px) {
      flex: 1 1 40%;
    }
    @media (min-width: 1300px) {
      flex: 1 1 20%;
    }
  }
  .image {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 200px;
    margin-bottom: 1rem;
    margin-top: 0;
    h3 {
      z-index: 100;
      color: white;
      text-align: center;
      margin: 0;
    }
    .gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: brightness(80%);
    }
  }
  a {
    margin-top: auto;
  }
`
const OtherServices = styled.div`
  ul {
    columns: 200px 3;
    margin-bottom: 2rem;
    font-size: 1.18rem;
    li {
      font-weight: 500;
    }
  }
  h2,
  h3 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`

const IndexPage = ({ data }) => {
  const { image, title, buttonText, buttonLink, introNode } = data.page.hero[0]
  return (
    <Layout isHome={true}>
      <SEO
        title={data.page.seo.title}
        description={data.page.seo.description}
      />
      <Hero image={image}>
        <div
          data-sal="slide-right"
          data-sal-duration="800"
          data-sal-easing="ease"
        >
          <h1>{title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: introNode.childMarkdownRemark.html,
            }}
          />
          <Link className="button" to={buttonLink}>
            {buttonText}
          </Link>
        </div>
      </Hero>
      <Section>
        <Container>
          <h2 className="mb-1 uppercase underlined">What we do</h2>
          <div
            className="mb-3"
            dangerouslySetInnerHTML={{ __html: data.page.whatWeDoText }}
          />
          <ServiceSection>
            {data.page.featuredServices.map(
              ({ title, slug, shortDescription, image }, index) => (
                <div
                  data-sal="slide-up"
                  data-sal-delay={100 * index}
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  key={slug}
                >
                  <Link to={`/services/${slug}`} className="image">
                    <Img fluid={image.fluid} alt={image.alt} />
                    <h3 className="service-title">{title}</h3>
                  </Link>
                  <p>{shortDescription}</p>
                  <Link to={`/services/${slug}`}>Find out more</Link>
                </div>
              )
            )}
          </ServiceSection>
        </Container>
      </Section>
      <Section color="paleBlue">
        <Container>
          <h2 className="uppercase underlined">
            Our Range of services include:
          </h2>
          <OtherServices
            dangerouslySetInnerHTML={{ __html: data.page.otherServices }}
          />
        </Container>
      </Section>
      <ContactSection />
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  {
    page: datoCmsHomePage {
      whatWeDoText
      otherServices
      hero {
        image {
          alt
          fluid(maxWidth: 2000) {
            ...GatsbyDatoCmsFluid
          }
        }
        title
        buttonText
        buttonLink
        introNode {
          childMarkdownRemark {
            html
          }
        }
      }
      featuredServices {
        title
        slug
        shortDescription
        image {
          alt
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      seo {
        title
        description
      }
    }
  }
`
